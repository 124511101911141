import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

let showModal = false;

@Component({
  selector: 'app-btn-modal-suscription',
  templateUrl: './btn-modal-suscription.component.html',
  styleUrls: ['./btn-modal-suscription.component.scss']
})

export class btnModalSuscription implements OnInit {
  @ViewChild('closeBtn', { static: false }) closeBtn!: ElementRef;
  @Output() returnConfirmation: EventEmitter<boolean> = new EventEmitter()

  constructor(private gtmService: GoogleTagManagerService) {}

  ngOnInit() {
    let 
      if_loaded = sessionStorage.getItem('loaded_vision_preferences') ? true : false,
      is_suscriber = sessionStorage.getItem('from_report_suscriber') ? true : false;

      // validar de nuevo source 
      if (location.search.includes('utm_source=Eloqua')) {
          is_suscriber = true
          sessionStorage.setItem('from_report_suscriber', '1')
      }
      
      // solo cuando no sea suscriptor y no se le haya mostrado el modal
      if (!if_loaded && !is_suscriber) {
        // Mostrar modal cuando el usuario haya hecho scroll al 40%
        window.addEventListener('scroll', function () {

          const scrollHeight = document.documentElement.scrollHeight;
          const clientHeight = document.documentElement.clientHeight;
          const scrollTop = window.scrollY || document.documentElement.scrollTop;
          // Calcular el porcentaje del scroll recorrido
          const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
  
          // Verificar si se ha alcanzado o superado el 40% del scroll
          if (scrolledPercentage >= 40 && !showModal) {
            showModal = true;
            $('#exampleModal').modal('show')
            sessionStorage.setItem('loaded_vision_preferences', 'true')
          }

        });

         // Mostrar modal despues de 1 minutos, igual si no es suscriptor
        setTimeout(() => {
          if (!showModal) {
            $('#exampleModal').modal('show')
            sessionStorage.setItem('loaded_vision_preferences', 'true')
          }
        }, 60000);
        
      }
  }


  onClose(): void {
    this.closeBtn.nativeElement.click()
  }

  saveData(): void {
    this.onClose()
    this.returnConfirmation.emit(true);
  }

  tag(eventCategory, eventAction, eventvalue = '') {

    const gtmTag = {
      EventoCategoria: eventCategory,
      EventoTipo: eventAction,
      EventoLanding: 'Vision Davivienda',
      EventoEtiqueta: eventvalue,
      event: 'eventclick'
    }

    this.gtmService.pushTag(gtmTag);
  }
}


